import {Box} from '@mui/material';
import toast from "react-hot-toast";
import {Container} from "react-bootstrap";
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import AddGenreForm from '../components/forms/AddGenreForm';
import apis from '../services';


const INITIAL_FORM_STATE = {type:''}

function AddUpdateGenre() {
    
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

    const [loading,setLoading]=useState(false)
    const [GenreData, setGenreData] = useState(INITIAL_FORM_STATE)
    const {id: genreId} = useParams();
    const navigate = useNavigate();

    const onChangeHandler = (e) => {
        const {name, value, files} = e.target;
        if (files)
            return setGenreData((prevState) => ({...prevState, image: files[0]}));
        setGenreData((prevState) => ({...prevState, [name]: value}));
    };

    const fetchGenreDetails = async (genreId) => {
        try {
            setLoading(true)
            const res = await apis.getAllGenre()
            console.log(res);
            if (res?.data?.data) {
              const data = res?.data?.data?.filter((item)=>item.genreId === genreId);
                setGenreData(data?.[0])
            }
            
            setLoading(false)
        } catch (e) {
            
            setLoading(false)
            console.error('ERROR in fetching Genre details', e);
        }
    };

    const onCreateGenreHandler = async (data) => {
        try {
            
            setLoading(true)
            const res = await apis.createGenre(data);
            if(res?.data?.success)
            {

                toast.success("Genre Created Successfully");
                setGenreData(INITIAL_FORM_STATE)
                navigate("/genre")
                setLoading(false)
         }else{
            toast.error(res?.data?.message);
            setLoading(false)
    
         }    
           } catch (e) {
            
            setLoading(false)
            const errorMessages = e?.message.errors;
            if (errorMessages) {      
              for (const key in errorMessages) {
                         if (errorMessages.hasOwnProperty(key)) {
                             toast.error(`${key} :  ${errorMessages[key]}`);
                         }
                     }
                 }else{
                     toast.error("An unexpected error occurred.");
                 }
            // if (e?.message) {
            //     return toast.error(e?.message);
            //   }
            // if (e.response && e.response.data && e.response.data.errors) {
            //     const errorMessages = e.response.data.errors;
        
            //     for (const key in errorMessages) {
            //         if (errorMessages.hasOwnProperty(key)) {
            //             toast.error(errorMessages[key]);
            //         }
            //     }
            // } else {
            //     // Handle unexpected errors
            //     toast.error("An unexpected error occurred.");
            // }

        }
    };
    const onUpdateGenreHandler = async (data) => {
        try {

            setLoading(true)
            const res = await apis.updateGenre(genreId,data);

            if(res?.data?.success)
            {
                toast.success("Genre Updated Successfully");
                navigate("/Genre")
                setGenreData('')   
            setLoading(false)
            }else{
                toast.error(res?.data?.message);
                setLoading(false)
        
             }  

        } catch (e) {
            
            setLoading(false)
            const errorMessages = e?.message.errors;
            if (errorMessages) {      
              for (const key in errorMessages) {
                         if (errorMessages.hasOwnProperty(key)) {
                             toast.error(`${key} :  ${errorMessages[key]}`);
                         }
                     }
                 }else{
                     toast.error("An unexpected error occurred.");
                 }
            // if (e?.message) {
            //     return toast.error(e?.message);
            //   }
            // if (e?.response && e?.response?.data && e?.response?.data?.errors) {
            //     const errorMessages = e.response.data.errors;
        
            //     for (const key in errorMessages) {
            //         if (errorMessages.hasOwnProperty(key)) {
            //             toast.error(errorMessages[key]);
            //         }
            //     }
            // } else {
            //     // Handle unexpected errors
            //     toast.error("An unexpected error occurred.");
            // }
        }
    }

    useEffect(() => {
        (async () => {
            if (genreId)
            
                await fetchGenreDetails(genreId);

        })()
    }, [genreId]);

    const onSubmitHandler = async (data) => {
        if (genreId) await onUpdateGenreHandler(GenreData)
        else await onCreateGenreHandler(GenreData)
    }
   
  
    return (
        <>
        <Box className="dashboard-main">
            
            <div className="dashboard-section-main">
            
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    // height: "100vh",
                    // overflow: "auto",
                }}
                className="custom-scroll"
            >
                <div className='container-fluid p-0'>
                <h3 className="section-title dark-text text-start mb-0">{genreId ? 'Update' : 'Add'} Genre</h3>
                    <AddGenreForm
                    loading={loading}
                        formData={GenreData}
                        onChangeHandler={onChangeHandler}
                        submitHandler={onSubmitHandler}
                        heading={genreId ? 'Update' : 'Add'}
                        genreId={genreId}
                    />
                </div>
            </Box>
            </div>
        </Box>
        </>
    );
}

export default AddUpdateGenre;
