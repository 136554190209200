import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import DeleteModal from "../components/DeleteModal";
import imageUrl from "../utils/ImageUrl";
import { useNavigate } from "react-router-dom";
import ThreeDots from "../components/shared/svgs/ThreeDots";
import backendImage from "../utils/backendImage";

const Banner = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState("");

  const [activeIndex, setActiveIndex] = useState("");
  const handleClose = () => {
    setShow(false);
    setId("");
  };
  const [values, setValues] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["getAllBanners"],
    () => apis.getAllBannerList(),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {
        let bannerData = data?.data?.data;
        setValues(bannerData);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    apis.deleteBannner,
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: ({ data }) => {
        if (data?.success) {
          refetch();
          console.log(data);
          toast.success("Banner Deleted successfully", { id: 1 });
          handleClose();
        } else {
          toast.error(data?.message, { id: 1 });
          handleClose();
        }
      },
    }
  );

  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };
  const toggleActiveIndex = (bannerId) => {
    setActiveIndex(activeIndex === bannerId ? "" : bannerId);
  };
  const handleDelete = async (id) => {
    mutateDelete(id);
    setId("");
  };

  if (isLoading || isLoadingDelete)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" className="yellow" />
      </div>
    );

  const renderBannerList =
    values?.length > 0 &&
    values.map((banner, index) => {
      const { name, url, location, bannerId, image } = banner;
      return (
        <tbody key={index}>
          <tr>
            <td>{index + 1}</td>

            <td>
              <img
                style={{ width: "50px", height: "35px" }}
                src={backendImage(image)}
                alt=""
              />
            </td>
            <td>{banner?.location}</td>
            <td>{banner?.url}</td>
            <td>
              <div
                style={{ position: "relative" }}
                onClick={() => toggleActiveIndex(bannerId)}
              >
                <ThreeDots />
                {bannerId === activeIndex && (
                  <div className="option-lists">
                    <ul>
                      <li onClick={() => navigate(`/edit-banner/${bannerId}`)}>
                        Edit Banner
                      </li>
                      <li onClick={() => handleShow(bannerId)} size="small">
                        Delete Banner
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {/* <Button variant="outline-danger" className='mx-2 my-2 button-size action-btn' onClick={() => handleShow(bannerId)}>Delete</Button> */}
            </td>
          </tr>
        </tbody>
      );
    });

  return (
    <>
      <Container fluid className="main-height">
        <Row>
          <Col lg={12} md={12} className="p-0">
            <div className="custom-chart-margin">
              <div className="d-flex justify-content-between">
                <h5 className="section-title">Banners</h5>
                <button
                  className="add-submit-btn"
                  onClick={() => navigate("/add-banner")}
                >
                  Add Banner
                </button>
              </div>

              <div className="user-table">
                <table className="table table-striped mt-3">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Banner Image</th>

                      <th scope="col">Banner Location</th>
                      <th scope="col">Banner URL</th>

                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {renderBannerList}
                </table>
              </div>
            </div>
          </Col>
        </Row>

        <DeleteModal
          show={show}
          handleClose={handleClose}
          handleSubmit={handleDelete}
          loader={isLoadingDelete}
          id={id}
          title={"Delete Banner"}
        />
      </Container>
    </>
  );
};
export default memo(Banner);
