import { Button,Form, Col, Container, Row, Spinner } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import DeleteModal from "../components/DeleteModal";
import imageUrl from "../utils/ImageUrl";

const SingBySong = () => {

  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search input value
 
  const [id, setId] = useState('');

  const handleClose = () => {
    setShow(false)
    setId('');
  }
  const [values, setValues] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["SingBySong"],
    () => apis.getUserSingHistory(),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {
        let userData = data?.data?.data;
        setValues(userData);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(apis.deleteUser, {
    onError: function ({ message }) {
      console.log(message);
    },
    onSuccess: ({ data }) => {
      if (data.status) {
        refetch()
        console.log(data)
        toast.success(data.message, { id: 1 })
        handleClose();
      }
    },
  });

  const handleShow = (id) => {
    setShow(true);
    setId(id)
  }

  const handleDelete = async (id) => {
    mutateDelete(id)
    setId('')
  }

  if (isLoading || isLoadingDelete) return <div
    style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner animation="border" className="yellow"/>
  </div>


  const renderSongList = values?.length > 0 && values?.filter((item) => {
    const searchValue = searchTerm.toLowerCase();
    return (
      item?.user?.userName?.toLowerCase().includes(searchValue) ||
      item?.song?.name?.toLowerCase().includes(searchValue) ||
      item?.song?.artistName?.toLowerCase().includes(searchValue)
    );
  })?.map((Song, index) => {
    const { singHistoryId,song, user ,recordingDate ,score } = Song;
    return (
      <tbody key={index}>
        <tr>
          <td>{index + 1}</td>
          <td>{user?.userName}</td>
          <td>{song?.name}</td>
          <td>{song?.artistName}</td>
          <td>{score}</td>
      
          <td>{recordingDate}</td>
      
       
          
        </tr>
      </tbody>
    )
  }
  );


  return <>
    <Container fluid className="main-height">
      <Row>
        <Col lg={12} md={12} className="p-0">
          <div className="custom-chart-margin">
            <div className="d-flex justify-content-between">

            <h5 className="section-title">
            Sing History Users
            </h5>
            <div className="m-3">            <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Form.Group>
              </div>

              </div>
           
            <div className="overflow-auto user-table">
              <table className="table table-striped mt-3">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Song Name</th>
                    <th scope="col">Artist Name</th>
                    <th scope="col">Score</th>
                    <th scope="col">Date</th>

                  </tr>
                </thead>
                {renderSongList}
              </table>
            </div>
          </div>
        </Col>
      </Row>

      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleSubmit={handleDelete}
        loader={isLoadingDelete}
        id={id}
        title={"Delete Song"}
      />
    </Container>

  </>
}
export default memo(SingBySong);