import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import DeleteModal from "../components/DeleteModal";
import imageUrl from "../utils/ImageUrl";
import { useNavigate } from "react-router-dom";
import backendImage from "../utils/backendImage";
import ThreeDots from "../components/shared/svgs/ThreeDots";

const Song = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const [activeIndex, setActiveIndex] = useState("");
  const [id, setId] = useState("");

  const handleClose = () => {
    setShow(false);
    setId("");
  };
  const [values, setValues] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["getAllSongs"],
    () => apis.getAllSongs({ sortBy: "name", order: "asc" }),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {

        
        let songData = data?.data?.data;
        setValues(songData);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    apis.deleteSong,
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: ({ data }) => {
        if(data?.success)
        {
          refetch();
          toast.success("Song Deleted Successfully", { id: 1 });
          handleClose();
        
        }
        else{
          toast.error("The song is already associated with a chart or song history and cannot be deleted", { id: 1 });
          handleClose();
        
        }
       },
    }
  );

  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const toggleActiveIndex = (songId) => {
    setActiveIndex(activeIndex === songId ? "" : songId);
  };

  const handleDelete = async (id) => {
    mutateDelete(id);
    setId("");
  };

  if (isLoading || isLoadingDelete )
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" className="yellow" />
      </div>
    );

  const renderSongList =
    values?.length > 0 &&
    values?.map((Song, index) => {
      const {
        name,
        chart,
        keywords,
        viewCount,
        lyrics,
        artistName,
        rank,
        genre,
        description,
        songId,
        imageFile,
      } = Song;
      return (
        <tbody key={index}>
          <tr>
            <td>{index + 1}</td>
            <td>
              <img
              style={{ width: "50px" ,
               height: "35px" }}
                src={backendImage(imageFile)}
                alt=""
              />
            </td>
            <td>{name}</td>
            <td>{artistName}</td>
            <td>{description}</td>
            <td>{rank}</td>

            <td>{viewCount}</td>

            <td>{genre}</td>

            <td>{keywords}</td>

            {/* <td>{chart}</td> */}

            <td>
              <div
                style={{ position: "relative" }}
                onClick={() => toggleActiveIndex(songId)}
              >
                <ThreeDots />
                {songId === activeIndex && (
                  <div className="option-lists">
                    <ul>
                      {/* <li onClick={() => window.location.replace(`http://137.184.187.215:5173/${songId}`)}>
                        Play Song
                      </li> */}
                      <li onClick={() => navigate(`/edit-song/${songId}`)} size="small">
                          Edit Song
                      </li>
                      <li onClick={() => handleShow(songId)} size="small">
                        Delete Song
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {/* <Button variant="outline-danger" className='mx-2 my-2 button-size action-btn' onClick={() => handleShow(songId)}>Delete</Button> */}
            </td>
          </tr>
        </tbody>
      );
    });

  return (
    <>
      <Container fluid className="main-height">
        <Row>
          <Col lg={12} md={12} className="p-0">
            <div className="custom-chart-margin">
              <h5 className="section-title"></h5>
              <div className="d-flex justify-content-between">
                <h5 className="section-title">Songs</h5>

                <button
                  className="add-submit-btn "
                  onClick={() => navigate("/add-song")}
                >
                  Add Songs
                </button>
              </div>

              <div className="overflow-scroll  user-table">
                <table className="table table-striped mt-3">
                  <thead>
                    <tr>
                      <th scope="col">#</th>

                      <th scope="col">Song Image</th>
                      <th scope="col">Song Name</th>
                      <th scope="col">Artist Name</th>
                      <th scope="col">Song Description</th>

                      <th scope="col">Song Rank</th>
                      <th scope="col">Song Views</th>
                      <th scope="col">Song Genre</th>

                      <th scope="col">Song Keyword</th>
                      {/* <th scope="col">Song Chart</th> */}
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {renderSongList}
                </table>
              </div>
            </div>
          </Col>
        </Row>

        <DeleteModal
          show={show}
          handleClose={handleClose}
          handleSubmit={handleDelete}
          loader={isLoadingDelete}
          id={id}
          title={"Delete Song"}
        />
      </Container>
    </>
  );
};
export default memo(Song);
