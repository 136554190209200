import axios from "axios";
const createBackendServer = (baseURL) => {

  // axios.defaults.withCredentials = true;
  const api = axios.create({
    baseURL: `${baseURL}/yelody`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": '*'
    },
    timeout: 60 * 1000,
  });

  const headers = {
    "Content-Type": "multipart/form-data"
  };


  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error.response.data;
      error.message = message || error.message
      /*if(error?.response?.data?.errors)
          error.errors = error?.response?.data?.errors;*/
      return Promise.reject(error)
    })

  /**********    GET REQUESTS    *********/













  const getRecipeStep = (id) => api.get(`/recipeStep/recipe/${id}`);

  const getCookStep = (id) => api.get(`/cookStep/recipe/${id}`);

  const getOneRecipeStep = (id) => api.get(`/recipeStep/${id}`);

  const getOneCookStep = (id) => api.get(`/cookStep/${id}`);

  const getOneRecipe = (id) => api.get(`/recipe/${id}`);

  const getAnalytics = () => api.get(`/miscellaneous/combinedStats`);

  const logout = () => api.get('/auth/logout');



  /**********    POST REQUESTS    *********/










  const createRecipeStep = (body) => api.post(`/recipeStep`, body);

  const createCookStep = (body) => api.post(`/cookStep`, body);

  const login = async (body) => api.post("/auth/admin", body);


  /**********    PUT REQUESTS    *********/




  const updateRecipe = (id, body) => api.put(`/recipe/update/${id}`, body);
  const updateRecipeStep = ({ recipeStepId, form_data }) => api.put(`/recipeStep/update/${recipeStepId}`, form_data);
  const updateCookStep = ({ cookStepId, form_data }) => api.put(`/cookStep/update/${cookStepId}`, form_data);



  /**********    Delete REQUESTS    *********/




  const deleteRecipeStep = (id) => api.delete(`/recipeStep/${id}`);
  const deleteCookStep = (id) => api.delete(`/cookStep/${id}`);



  /**********    Banner Apis Start    *********/

  const createBanner = (body) => api.post(`/banner/addBanner`, body);
  const getOneBanner = (id) => api.get(`/banner/getBannerById?id=${id}`);
  const getBannerImage = (id) => api.get(`/banner/getBannerImage?id=${id}`);
  const deleteBannner = (id) => api.delete(`/banner/deleteBanner?id=${id}`);
  const getAllBannerList = () => api.get(`/banner/getBannerList`);
  const updateBanner = (id, body) => api.put(`/banner/updateBanner?id=${id}`, body,headers);

  /**********    Banner Apis End    *********/

  /**********    User Apis Start    *********/
  const addUser = (body) => api.post(`/user/postUser`, body);
  const getUserDetailById = (id) => api.delete(`/user/getUserDetails${id}`);
  const getAllUsers = () => api.get(`/user/listUsers`);
 
  const getUserSingHistory = () => api.get(`/singHistory/getAllSingHistories`);
 
  const addUserSingHistory = (body) => api.post(`/singHistory/postSingHistory`, body);
  const updateUserDetails = (id, body) => api.put(`/user/updateUser?id=${id}`, body);
  const deleteUser = (id) => api.delete(`/user/deleteUser?id=${id}`);
  const deleteUserSingHistory = (id) => api.delete(`/singHistory/deleteSingHistory${id}`);

  /**********    User Apis End    *********/


  /**********    Banner Apis Start    *********/

  const createChart = (body) => api.post(`/chart/postChart`, body);
  const updateChart = (id, body) => api.put(`/chart/updateChart?id=${id}`, body);
  const getOneChart = (id) => api.get(`/chart/getChartById?id=${id}`);
  const getAllCharts = () => api.get(`/chart/listCharts`);
  const deleteChart = (id) => api.delete(`/chart/deleteChart?id=${id}`);

  /**********    Banner Apis End    *********/


  /**********    Banner Apis Start    *********/

  const incrementSungSongCount = (id) => api.post(`/user/incrementSingCountById?id=${id}`);
  const createSong = (body) => api.post(`/song/postSong`, body);
  const updateSong = (id,body) => api.put(`/song/updateSong?id=${id}`,body);
  

  const getAllSongs = (body) => api.post(`/song/listSongs`,body);
  const incrementSungViewCount = (id,songId) => api.post(`/song/incrementViewById?id=${id}&songId=${songId}`);
  const addSongToChart = (body) => api.put(`/chart/postSongtoChart`, body);
  const uploadSong = (body) => api.post(`/song/upload`, body);
  const deleteSong = (id) => api.delete(`/song/deleteSong?id=${id}`);
  const getOneSong = (id) => api.get(`/song/getSongById?id=${id}`);
 
  /**********    Banner Apis End    *********/



  /**********    Genre Apis Start    *********/

  const createGenre = (body) => api.post(`/genre/postGenre?type=${body.type}`);
  const updateGenre = (id,body) => api.put(`/genre/updateGenre?id=${id}&type=${body?.type}`);
  const getAllGenre = () => api.get(`/genre/listGenre`);
  
  const getOneGenre = (id) => api.get(`/chart/getChartById?id=${id}`);
  const deleteGenre = (id) => api.delete(`/genre/deleteGenre?id=${id}`);
  const getAgegroupKeywordAndGenre = () => api.get(`/miscellaneous/age_key_genre`);

  /**********    Keyword Apis End    *********/

  const getAllKeywords = () => api.get(`/keyword/getKeywordList`);
  const getOneKeywords = (id) => api.get(`/keyword/getKeywordById?id=${id}`);
 
  const createKeywords = (body) => api.post(`/keyword/addKeyword?keyword=${body.keyword}`);
  const updateKeywords = (id,body) => api.put(`/keyword/updateKeyword?id=${id}&keyword=${body.keyword}`);
  const deleteKeywords = (id) => api.delete(`/keyword/deleteKeyword?id=${id}`);

  /**********    Age Group Apis Start    *********/

  const createAgeGroup = (body) => api.post(`/ageGroup/addAgeGroup?ageGroup=${body.ageGroup}`);
  const getOneAgeGroup = (id) => api.get(`/ageGroup/getAgeGroupbyId?id=${id}`);
  const getAllAgeGroup = () => api.get(`/ageGroup/getAgeGroupList`);
  const updateAgeGroup = (id,body) => api.put(`/ageGroup/updateAgeGroup?id=${id}`,body);
  const deleteAgeGroup = (id) => api.delete(`/ageGroup/deleteAgeGroup?id=${id}`);


  /**********    Age Group Apis End    *********/

  const postAdmin = (body) => api.post(`/admin/postAdmin` , body);
  const adminLogin = (body) => api.post(`/admin/loginAdmin` , body);
  const forgetPassword = (body) => api.get(`/admin/forgotPass?email=${body.email}`);
  const OTPVerification  = (body) => api.post(`/admin/otpVerification?email=${body.email}&otp=${body.otp}` ,);


  /**********    Others Apis Start    *********/

  /**********    Others Apis End    *********/

  return {

    // /  Banner start /

    createBanner,
    getOneBanner,
    getBannerImage,
    getAllBannerList,
    deleteBannner,
    updateBanner,

    adminLogin,
    forgetPassword,
    OTPVerification,
    // /  Banner end /


    // /  User start /
    getUserSingHistory,
    addUser,
    getUserDetailById,
    getAllUsers,
    deleteUser,
    updateUserDetails,
    addUserSingHistory,
    deleteUserSingHistory,

    // /  keyword end /
    getAllKeywords,
    updateKeywords,
    createKeywords,
    deleteKeywords,
    getOneKeywords,
    // /  Chart start /

    updateChart,
    getOneChart,
    getAllCharts,
    createChart,
    deleteChart,

    // /  Chart end /


    // /  Song start /
    updateSong,
    createSong,
    getAllSongs,
    addSongToChart,
    incrementSungSongCount,
    incrementSungViewCount,
    uploadSong,
    deleteSong,
    getOneSong,
    // /  Song end /


    // /  Genre start /

    createGenre,
    getAllGenre,
    updateGenre,
    getOneGenre,
    deleteGenre,
    getAgegroupKeywordAndGenre,

    // /  Genre end /


    // /  Age Group start /
    
    createAgeGroup,
    getOneAgeGroup,
    getAllAgeGroup,
    updateAgeGroup,
    deleteAgeGroup,
 



    getOneRecipeStep,
    deleteRecipeStep,
    createRecipeStep,
    updateRecipe,
    getOneRecipe,
    getAnalytics,
    login,
    logout,
    getRecipeStep,
    updateRecipeStep,
    createCookStep,
    updateCookStep,
    deleteCookStep,
    getCookStep,
    getOneCookStep,
  };
};


const apis = createBackendServer(process.env.REACT_APP_URL);


export default apis;
