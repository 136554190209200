import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import "../assets/css/header.css";
import Logo from '../assets/images/logo.webp'
import Image from '../assets/images/profile.png'
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/slices/userSlice";
import apis from "../services";
import LogoutIcon from '@mui/icons-material/Logout';
import Hamburgur from "./shared/svgs/Hamburgur";
export function BasicCard({
  name,
  email,
  close,
  
}) {
  

  const logoutUser = async () => {
    const res = await apis.logout();
  };

  const dispatch = useDispatch();

  return (
    <Card sx={{ minWidth: 275, padding: "20px 0",borderRadius:"10px"  }} className="light-bg">
      <div className="d-flex text-center justify-content-center">
        {/* <IconButton color="inherit" variant="contained"> */}
          <Avatar src={Image} />
    
      </div>

      <CardContent className="text-center">
        <Typography variant="p" component="div" fontSize={14}>
          {email}
        </Typography>
      </CardContent>
          
      <CardActions className="justify-content-center">
        <Link to="/sign-in">
          <button
            onClick={() => {
              dispatch(logout());
              close();
              logoutUser();
            }}
            className="lp-btn"
          >
            {" "}
            <LogoutIcon/>{" "}
            logout
          </button>
        </Link>
      </CardActions>
    </Card>
  );
}

function Header({hamburgerActive , SetHamburgurActive}) {
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileModal, setProfileModal] = useState(false);

  let { admin } = useSelector((store) => store.user);
  const toggleProfileModal = () => setProfileModal((prevState) => !prevState);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popOpen = Boolean(anchorEl);
  const id = popOpen ? "simple-popover" : undefined;

  console.log(hamburgerActive);

  return (
    <>
      <header className="custom-header" id="custom-header">
        <div className="logo-section" onClick={() => SetHamburgurActive(!hamburgerActive)}>
          {/* <Link to="/">
            {" "}
            <img src={Logo} alt="logo" className="dashboard-logo" />{" "}
          </Link> */}
          <Hamburgur/>
        </div>

        <div
        className={
            isShow ? 'header-main header-show' : 'header-main header-hide'
        }
        >
          <ul className="header-body">
                  <li>
                    <IconButton
                      color="inherit"
                      variant="contained"
                      onClick={handleClick}
                    >
                      <Avatar
                        sx={{ border: "1px solid rgba(0,0,0,0.5)" }}
                        src={Image}
                      />
                    </IconButton>
                    <Popover
                      // id={id}
                      open={popOpen}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <BasicCard
                        toggleProfileModal={toggleProfileModal}
                        close={handleClose}
                        {...admin}
                    />
                    </Popover>
                  </li>
          </ul>
        </div>
      </header>
    </>
  );
}

export default Header;
