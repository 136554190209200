import {Box} from '@mui/material';
import toast from "react-hot-toast";
import {Container} from "react-bootstrap";
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import apis from '../services';
import AddAgeGroupForm from '../components/forms/AddAgeGroupForm';


const INITIAL_FORM_STATE = {ageGroup:''}

function AddUpdateAgeGroup() {
    
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

const [loading,setLoading]=useState(false)
    const [AgeGroupData, setAgeGroupData] = useState(INITIAL_FORM_STATE)
    const {id: AgeGroupId} = useParams();
    const navigate = useNavigate();

    const onChangeHandler = (e) => {
        const {name, value, files} = e.target;
        if (files)
            return setAgeGroupData((prevState) => ({...prevState, image: files[0]}));
        setAgeGroupData((prevState) => ({...prevState, [name]: value}));
    };

    const fetchAgeGroupDetails = async (AgeGroupId) => {
        try {
            
            setLoading(true)
            const res = await apis.getOneAgeGroup(AgeGroupId)
            console.log(res);
            if (res?.data?.data) {
                setAgeGroupData({ageGroup:res?.data?.data?.name})
            }
            
            setLoading(false)
        } catch (e) {
            
            setLoading(false)
            // if (e.response && e.response.data && e.response.data.errors) {
            //     const errorMessages = e.response.data.errors;
        
            //     for (const key in errorMessages) {
            //         if (errorMessages.hasOwnProperty(key)) {
            //             toast.error(errorMessages[key]);
            //         }
            //     }
            // } else {
            //     // Handle unexpected errors
            //     toast.error("An unexpected error occurred.");
            // }
        }
    };

    const onCreateAgeGroupHandler = async (data) => {
        try {
            
            setLoading(true)
            const res = await apis.createAgeGroup(data);
             if(res?.data?.success)
             {  
             toast.success("AgeGroup Created Successfully");
                setAgeGroupData(INITIAL_FORM_STATE)
                navigate("/age-group")
        
                setLoading(false)
             }
                else{
                    if(res?.data?.message)
                    {
                        toast.error(res?.data?.message);
                    
                    }
                    else{
                        toast.error("Something error while creating age group may be duplicate entry");
                    
                    }
                    setLoading(false)
            
                 }  
        } catch (e) {
            
            setLoading(false)
            const errorMessages = e?.message.errors;
            if (errorMessages) {      
              for (const key in errorMessages) {
                         if (errorMessages.hasOwnProperty(key)) {
                             toast.error(`${key} :  ${errorMessages[key]}`);
                         }
                     }
                 }else{
                     toast.error("An unexpected error occurred.");
                 }
            // if (e?.message) {
            //     return toast.error(e?.message);
            //   }
            //   if (e?.response && e?.response?.data && e?.response?.data?.errors){
            //     const errorMessages = e.response.data.errors;
        
            //     for (const key in errorMessages) {
            //         if (errorMessages.hasOwnProperty(key)) {
            //             toast.error(errorMessages[key]);
            //         }
            //     }
            // } else {
            //     // Handle unexpected errors
            //     toast.error("An unexpected error occurred.");
            // }

        }
    };
    const onUpdateAgeGroupHandler = async (data) => {
        try {
           
            setLoading(true)
            const res = await apis.updateAgeGroup(AgeGroupId,{ ...data,id:AgeGroupId});
           if(res?.data?.success)
           {
            
                toast.success("Age Group Updated Successfully");
                navigate("/age-group")
                setAgeGroupData('')
            
           }
                else{
                    toast.error(res?.data?.message);
                    setLoading(false)
            
                 }  
            setLoading(false)
        } catch (e) {
    
            setLoading(false)
            const errorMessages = e?.message.errors;
            if (errorMessages) {      
              for (const key in errorMessages) {
                         if (errorMessages.hasOwnProperty(key)) {
                             toast.error(`${key} :  ${errorMessages[key]}`);
                         }
                     }
                 }else{
                     toast.error("An unexpected error occurred.");
                 }
            // if (e?.message) {
            //     return toast.error(e?.message);
            //   }
            // if (e?.response && e?.response?.data && e?.response?.data?.errors) {
            //     const errorMessages = e.response.data.errors;
        
            //     for (const key in errorMessages) {
            //         if (errorMessages.hasOwnProperty(key)) {
            //             toast.error(errorMessages[key]);
            //         }
            //     }
            // } else {
            //     // Handle unexpected errors
            //     toast.error("An unexpected error occurred.");
            // }
        }
    }

    useEffect(() => {
        (async () => {
            if (AgeGroupId)
                await fetchAgeGroupDetails(AgeGroupId);

        })()
    }, [AgeGroupId]);

    const onSubmitHandler = async (data) => {
        if (AgeGroupId) await onUpdateAgeGroupHandler(AgeGroupData)
        else await onCreateAgeGroupHandler(AgeGroupData)
    }
   
  
    return (
        <>
        <Box className="dashboard-main">
            
            <div className="dashboard-section-main">
            
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    // height: "100vh",
                    // overflow: "auto",
                }}
                className="custom-scroll"
            >
                <div className='container-fluid p-0'>
                <h3 className="section-title dark-text text-start mb-0">{AgeGroupId ? 'Update' : 'Add'} Age Group</h3>
                    <AddAgeGroupForm
                    loading={loading}
                        formData={AgeGroupData}
                        onChangeHandler={onChangeHandler}
                        submitHandler={onSubmitHandler}
                        heading={AgeGroupId ? 'Update' : 'Add'}
                        AgeGroupId={AgeGroupId}
                    />
                </div>
            </Box>
            </div>
        </Box>
        </>
    );
}

export default AddUpdateAgeGroup;
