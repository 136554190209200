import {Box} from '@mui/material';
import toast from "react-hot-toast";
import {Container} from "react-bootstrap";
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import AddBannerForm from '../components/forms/AddBannerForm';
import apis from '../services';


const INITIAL_FORM_STATE = {image: '', location:'Side' ,url:"", language:"EN_US",image:''}

function AddUpdateBanner() {
    
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

    const [loading,setLoading]=useState(false)
    const [bannerData, setBannerData] = useState(INITIAL_FORM_STATE)
    const {id: bannerId} = useParams();
    const navigate = useNavigate();
    const [imagePreview, setImagePreview] = useState(null);

    const onChangeHandler = (e) => {
        const {name, value, files} = e.target;
        if (files)
        {
            setImagePreview(URL.createObjectURL(files[0]));
            setBannerData((prevState) => ({...prevState, image: files[0]}));
        }else{
            setBannerData((prevState) => ({...prevState, [name]: value}));
        }
    };

    const fetchBannerDetails = async (bannerId) => {
        try {
            
            setLoading(true)
            const res = await apis.getOneBanner(bannerId)
            console.log(res);
            if (res?.data?.data) {
                setBannerData(res?.data?.data)
            }
           
            setLoading(false)
        } catch (e) { 
            
            setLoading(false)
        //      if (e.response && e.response.data && e.response.data.errors) {
        //     const errorMessages = e.response.data.errors;
    
        //     for (const key in errorMessages) {
        //         if (errorMessages.hasOwnProperty(key)) {
        //             toast.error(errorMessages[key]);
        //         }
        //     }
        // } else {
        //     // Handle unexpected errors
        //     toast.error("An unexpected error occurred.");
        // }
        }
    };

    const onCreateBannerHandler = async (data) => {
        try {
          if(data?.image == "")
          {
           return toast.error("Please select image");
          }
            setLoading(true)
            const form_data = new FormData();
            for (const [key, value] of Object.entries(data)) {
                if (typeof value === 'object' && key !== "image") {
                  for (const [nestedKey, nestedValue] of Object.entries(value)) {
                    form_data.append(`${key}[${nestedKey}]`, nestedValue);
                  }
                } 
                else {
                  form_data.append(key, value);
                }
            }

            const res = await apis.createBanner(form_data);
                toast.success("Banner Created Successfully");

                setBannerData(INITIAL_FORM_STATE)
                navigate("/banner")
        
                setLoading(false)
        } 
        
        catch (e) {
            
            setLoading(false)
         
            const errorMessages = e?.message.errors;
            if (errorMessages) {      
              for (const key in errorMessages) {
                         if (errorMessages.hasOwnProperty(key)) {
                             toast.error(`${key} :  ${errorMessages[key]}`);
                         }
                     }
                 }else{
                     toast.error("An unexpected error occurred.");
                 }
            // if (e?.message) {
            //     return toast.error(e?.message);
            //   }
            // if (e?.response && e?.response?.data && e?.response?.data?.errors) {
            //     const errorMessages = e.response.data.errors;
        
            //     for (const key in errorMessages) {
            //         if (errorMessages.hasOwnProperty(key)) {
            //             toast.error(errorMessages[key]);
            //         }
            //     }
            // } else {
            //     // Handle unexpected errors
            //     toast.error("An unexpected error occurred.");
            // }

        }
    };
    const onUpdateBannerHandler = async (data) => {
        try {
            setLoading(true)
            console.log(data?.image,"data")
            
            const form_data = new FormData();
            for (const [key, value] of Object.entries(data)) {
                if (typeof value === 'object' && key !== "image") {
                  for (const [nestedKey, nestedValue] of Object.entries(value)) {
                    form_data.append(`${key}[${nestedKey}]`, nestedValue);
                }

                } 
                else {
                    if(key === "image")
                    {
                        if(value?.name)
                        {
                            form_data.append(key, value);
                        }
                    }
                    else{
                        form_data.append(key, value);
                   
                    }
                }
            }
            const res = await apis.updateBanner(bannerId, form_data);
        
                toast.success("Banner Updated Successfully");
                navigate("/banner")
                setBannerData('')
                setLoading(false)
            
        } catch (e) {
            
            setLoading(false)
       console.log(e?.message,"EEEEEEEEEEEEEEE")
       const errorMessages = e?.message.errors;
       if (errorMessages) {      
         for (const key in errorMessages) {
                    if (errorMessages.hasOwnProperty(key)) {
                        toast.error(`${key} :  ${errorMessages[key]}`);
                    }
                }
            }else{
                toast.error("An unexpected error occurred.");
            }
            // if (e?.message) {
            //     return toast.error(e?.message);
            //   }
            // if (e?.response && e?.response?.data && e?.response?.data?.errors) {
            //     const errorMessages = e.response.data.errors;
        
            //     for (const key in errorMessages) {
            //         if (errorMessages.hasOwnProperty(key)) {
            //             toast.error(errorMessages[key]);
            //         }
            //     }
            // } else {
            //     toast.error("An unexpected error occurred.");
            // }
        }
    }

    useEffect(() => {
        (async () => {
            if (bannerId)
                await fetchBannerDetails(bannerId);

        })()
    }, [bannerId]);

    const onSubmitHandler = async (data) => {
        if (bannerId) await onUpdateBannerHandler(bannerData)
        else await onCreateBannerHandler(bannerData)
    }
   
  
    return (
        <>
        <Box className="dashboard-main">
            
            <div className="dashboard-section-main">
            
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    // height: "100vh",
                    // overflow: "auto",
                }}
                className="custom-scroll"
            >
                <div className='container-fluid p-0'>
                <h3 className="section-title dark-text text-start mb-0">{bannerId ? 'Update' : 'Add'} Banner</h3>
                    <AddBannerForm
                        loading={loading}
                        formData={bannerData}
                        onChangeHandler={onChangeHandler}
                        submitHandler={onSubmitHandler}
                        heading={bannerId ? 'Update' : 'Add'}
                        bannerId={bannerId}
                        imagePreview={imagePreview}
                    />
                </div>
            </Box>
            </div>
        </Box>
        </>
    );
}

export default AddUpdateBanner;
