import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TbPhotoEdit } from "react-icons/tb";
import dummy from "../../assets/images/dummy.png"
import backendImage from "../../utils/backendImage";

const AddChartForm = ({
  formData,
  submitHandler,
  onChangeHandler,
  heading,
  chartId,
  loading,
  imagePreview
}) => {
  const reqireState = chartId ? false : true;

  const GRIND_SIZE = ["Coarse", "Fine", "Medium"];

  const [chips, setChips] = useState([]);
  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
 

  const handleDeleteChip = (chip, index) => {
    setChips(chips.filter((c, i) => i !== index));
  };

  const submit = (e) => {
    e.preventDefault();
    submitHandler(formData);
    // e.currentTarget.reset();
  };

  return (
    <Fragment>
      <div className="multiform">
        <Form onSubmit={submit} className="my-4">
          <Row className="d-flex justify-content-center align-items-center">
            
          <Col xs={12} md={12} lg={12}>
            <div className="profile_image_wrapper">
          <img
            src={imagePreview || backendImage(formData?.image)}
            onClick={triggerFileInput}
            className="img-fluid d-block mx-auto ProfileImage shadow-sm"
            alt="cate"
            onError={(e) => {
              e.target.src = dummy;
            }}
          />
          <TbPhotoEdit
          size={30}
          onClick={triggerFileInput}
            className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
          />
           <input
            type="file"
            name="image"
            // required={reqireState}
            accept="image/png, image/gif, image/jpeg"
            onChange={(event)=>{
              onChangeHandler(event)
                }}
            ref={fileInputRef}
            className="d-none"
          />
        </div>
            </Col>
            
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Chart Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Chart Name"
                  required
                  onChange={onChangeHandler}
                  value={formData?.name}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Chart Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Chart Title"
                  required
                  onChange={onChangeHandler}
                  value={formData?.title}
                />
              </Form.Group>
            </Col>
         
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Chart Rank</Form.Label>
                <Form.Control
                  type="number"
                  name="rank"
                  placeholder="Chart Rank"
                  required
                  min={1}
                  // max={10}
                  onChange={onChangeHandler}
                  value={formData?.rank}
                />
              </Form.Group>
            </Col> 
              <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Chart Description</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="description"
                  placeholder="Chart Description"
                  required
                  onChange={onChangeHandler}
                  value={formData?.description}
                />
              </Form.Group>
            </Col>
            {/* <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Chart Image</Form.Label>
                <div className="add-recipe-upload">
                <div className="NFT-image-holder">
                    <input 
                    
                      required={reqireState}
                    type="file" accept="image/png, image/gif, image/jpeg" onChange={(event)=>{
                      onChangeHandler(event)
                        }}/>
                  </div>

                </div>
              </Form.Group>
            </Col> */}
          
         
          


            <Col xs={12} md={12} lg={12}>
              <div className="update-submit-btn-parent">
              <Button className=" update-submit-btn btn btn-warning" type="submit">
                {" "}
                {loading ? "Loading":  heading + "Chart"}
              </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddChartForm;
