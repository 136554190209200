import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
const AddGenreForm = ({
  formData,
  submitHandler,
  onChangeHandler,
  heading,
  genreId,
  loading
}) => {
  const reqireState = genreId ? false : true;

  const location = ["Side", "Main", "Mobile"];

  const [chips, setChips] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  const submit = (e) => {
    e.preventDefault();
    submitHandler(formData);
    // e.currentTarget.reset();
  };

  return (
    <Fragment>
      <div className="multiform">
        <Form onSubmit={submit} className="my-4">
          <Row className="d-flex justify-content-center align-items-center">
          
          <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Genre Name</Form.Label>
                <Form.Control
                  type="text"
                  name="type"
                  placeholder="Genre Name"
                  required={reqireState}
                  maxLength={40}
                  onChange={onChangeHandler}
                  value={formData?.type}
                />
              </Form.Group>
            </Col>
      
            <Col xs={12} md={12} lg={12}>
              <div className="update-submit-btn-parent">
              <Button className="update-submit-btn btn btn-warning" type="submit">
                {" "}
                {loading ? "Loading":  heading + " Genre"}
           
              </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddGenreForm;
