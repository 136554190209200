import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import DeleteModal from "../components/DeleteModal";
import imageUrl from "../utils/ImageUrl";
import { useNavigate } from "react-router-dom";
import ThreeDots from "../components/shared/svgs/ThreeDots";

const Keywords = () => {


  const [activeIndex, setActiveIndex] = useState('');
  const [show, setShow] = useState(false);
 const navigate = useNavigate();
  const [id, setId] = useState('');

  const handleClose = () => {
    setShow(false)
    setId('');
  }
  const [values, setValues] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["getAllKeywords"],
    () => apis.getAllKeywords(),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {
        let KeywordsData = data?.data?.data;
        setValues(KeywordsData);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(apis.deleteKeywords, {
    onError: function ({ message }) {
      toast.error(message);
      handleClose();
    },
    onSuccess: ({ data }) => {
   if(data?.success)
   {
    refetch()
    toast.success("Keywords Deleted Sucessfully", { id: 1 })
    handleClose();
   }
   else{
    toast.error("The keyword is already associated with a chart or song or song history and cannot be deleted", { id: 1 });
        
    handleClose();
   }
    },
  });

  const handleShow = (id) => {
    setShow(true);
    setId(id)
  }

  const handleDelete = async (id) => {
    mutateDelete(id)
    setId('')
  }
  const toggleActiveIndex = (keywordId) => {
    setActiveIndex(activeIndex === keywordId ? '' : keywordId);
  }
  if (isLoading || isLoadingDelete) return <div
    style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner animation="border" className="yellow" />
  </div>


  const renderKeywordsList = values?.length > 0 && values.map((Keywords, index) => {
    const { name, keywordId } = Keywords;
    return (
      <tbody key={index}>
        <tr>
          <td>{index + 1}</td>

          <td>{name}</td>

          <td>
          <div style={{position : 'relative'}} onClick={() => toggleActiveIndex(keywordId)}>
              <ThreeDots/>
              {keywordId === activeIndex 
              &&
              <div className="option-lists">
                <ul>
                  <li onClick={() => navigate(`/edit-keywords/${keywordId}`)}>Edit Keyword</li>
                  <li onClick={() => handleShow(keywordId)} size="small">Delete Keyword</li>
                 </ul>
              </div>
              }

            </div>
            {/* <Button variant="outline-danger" className='mx-2 my-2 button-size action-btn' onClick={() => handleShow(keywordId)}>Delete</Button> */}
          </td>
        </tr>
      </tbody>
    )
  }
  );


  return <>
    <Container fluid className="main-height">
      <Row>
        <Col lg={12} md={12} className="p-0">
          <div className="custom-chart-margin">
            <div className="d-flex justify-content-between">

              <h5 className="section-title">
                Keywords
              </h5>
              <button className="add-submit-btn" onClick={() => navigate('/add-keywords')}>
                Add Keyword
              </button>
            </div>


            <div className="overflow-auto user-table">
              <table className="table table-striped mt-3">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Keywords Type</th>
                     <th scope="col">Action</th>

                  </tr>
                </thead>
                {renderKeywordsList}
              </table>
            </div>
          </div>
        </Col>
      </Row>

      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleSubmit={handleDelete}
        loader={isLoadingDelete}
        id={id}
        title={"Delete Keywords"}
      />
    </Container>

  </>
}
export default memo(Keywords);