import './App.css';
import {SideBar,Header,ProtectedRoute} from './components/Index';
import {BrowserRouter as Router,Route,Routes,useLocation} from 'react-router-dom';
import Home from './screens/Home';
import Users from './screens/Users';
import Signin  from './screens/Signin';
import AddUpdateBanner from './screens/AddUpdateBanner';

import { useEffect, useState } from 'react';
import Banner from './screens/Banner';
import AddUpdateSong from './screens/AddUpdateSong';

import Song from './screens/Song';
import AddUpdateChart from './screens/AddUpdateChart';
import Chart from './screens/Chart';
import SingBySong from './screens/SingBySong';
import Genre from './screens/Genre';
import AddUpdateGenre from './screens/AddUpdateGenre';
import AgeGroup from './screens/AgeGroup';
import AddUpdateAgeGroup from './screens/AddUpdateAgeGroup';
import AddUpdateKeywords from './screens/AddUpdateKeywords';
import Keywords from './screens/Keywords';
import Forget from './screens/Forget';
import Verification from './screens/Verification';

function App() {
  
  const location = useLocation();
  const [hamburgerActive , SetHamburgurActive] = useState(false)

  return (
    <>    
    <div className="App main-body">     
      {location.pathname === "/sign-in" || location.pathname === "/forget" || location.pathname === "/verification"? "":<SideBar hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>}
      <div className="main-section">

        {location.pathname === "/sign-in" || location.pathname === "/forget" || location.pathname === "/verification" ? "": <Header hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>}
     
        <Routes>
       
        <Route path="/sign-in" element={<Signin/>}  />
        <Route path="/forget" element={<Forget/>}  />
        <Route path="/verification" element={<Verification/>}  />
       

        {/* <Route exact path="/"> */}
        

        <Route exact path="/">
          
        <Route path="users" element={<Users hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        <Route path="genre" element={<Genre hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
       
        <Route path="banner" element={<Banner hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        <Route path="song" element={<Song hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
      
        <Route path="age-group" element={<AgeGroup hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
       
        <Route path="add-age-group" element={<AddUpdateAgeGroup hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        <Route path="edit-age-group/:id" element={<AddUpdateAgeGroup hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
    

        <Route path="keywords" element={<Keywords hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
      
        <Route path="add-keywords" element={<AddUpdateKeywords hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        <Route path="edit-keywords/:id" element={<AddUpdateKeywords hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
    

        
        <Route path="sing-history-user" element={<SingBySong hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        <Route path="sing-history-song" element={<SingBySong hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />

       <Route path="chart" element={<Chart hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        
                
        <Route path="add-banner" element={<AddUpdateBanner hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        <Route path="edit-banner/:id" element={<AddUpdateBanner hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
                        
        <Route path="add-song" element={<AddUpdateSong hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        
        <Route path="edit-song/:id" element={<AddUpdateSong hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        
        <Route path="add-chart" element={<AddUpdateChart hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />
        <Route path="edit-chart/:id" element={<AddUpdateChart hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />

        <Route path="add-genre" element={<AddUpdateGenre hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />

        <Route path="edit-genre/:id" element={<AddUpdateGenre hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>} />

              
        <Route path="/" element={<Home hamburgerActive={hamburgerActive} SetHamburgurActive={SetHamburgurActive}/>}  />
        </Route>

                
        </Routes>
      </div>
    </div>
    </>


  );
}

export default App;
