import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Table,
  Container,
  Form,
  Modal as CustomModal,
  Row,
} from "react-bootstrap";
import Cross from "./shared/svgs/Cross";
import backendImage from "../utils/backendImage";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";

const AddSongsToChartModal = ({
  show,
  handleClose,
  handleSubmitAddSongs,
  id,
  loader,
  title,
  selectedDetails
}) => {
  const [values, setValues] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["getAllSongs"],
    () => apis.getAllSongs({ sortBy: "name", order: "asc" }),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {

        let songData = data?.data?.data?.filter((item) => {
        return item.chart === selectedDetails.name || item.chart === null
        })
       
        
       setValues(songData);

        console.log(selectedDetails?.songIds, 'selectedDetails')

      setSelectedIds([...selectedDetails?.songIds]);
  
      },
    }
  );

  const handleSelect = (songId) => {
    if (selectedIds?.includes(songId)) {
      setSelectedIds(selectedIds?.filter((id) => id !== songId));
    } else {
      setSelectedIds([...selectedIds, songId]);
    }
  };

  console.log(selectedIds, "selectedIds");


  useEffect(() => {
    refetch()


  }, [id])

  return (
    <CustomModal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      className="custom-modal"
    >
      <CustomModal.Header style={{ position: "relative" }}>
        <CustomModal.Title className="text-color">{title + " " + selectedDetails?.name}</CustomModal.Title>
        <div
          className="cross"
          onClick={() => handleClose()}
          style={{ cursor: "pointer" }}
        >
          <Cross />
        </div>
      </CustomModal.Header>
      <CustomModal.Body>
        <div className="overflow-auto user-table" style={{ maxHeight: "400px", overflowY: "auto" }}>
          <table className="table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Song Image</th>
                <th scope="col">Song Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>

            <tbody>
              {values?.length > 0 ? (
                values?.map((song, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        style={{
                          width: "50px",
                          height: "40px"
                        }}
                        src={backendImage(song.imageFile)}
                        alt=""
                      />
                    </td>
                    <td>{song.name}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(song.songId)}
                        onChange={() => handleSelect(song.songId)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <td colSpan={4}>No record found</td>
              )}
            </tbody>
          </table>
        </div>
      </CustomModal.Body>
      <CustomModal.Footer>
        <div className="d-flex" style={{ gap: "20px" }}>
          <button
            className="custom-btn round-btn"
            onClick={() => handleClose()}
          >
            Close
          </button>

          <button
            onClick={() => handleSubmitAddSongs(selectedIds)}
            className="custom-btn round-btn"
            type="submit"
            disabled={loader || selectedIds?.length === 0}
          >
            {loader ? "Loading" : "Added Selected"}
          </button>
        </div>
      </CustomModal.Footer>
    </CustomModal>
  );
};

export default AddSongsToChartModal;
