import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import apis from "../services";
import { loginValidate } from "../validation/loginValidation";
import { errorValidate } from "../validation/errorHandleJoi";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setUser } from "../redux/slices/userSlice";
import imageUrl from "../utils/ImageUrl";
import { useMutation } from "@tanstack/react-query";

const Signin = () => {

  const navigate = useNavigate();

 const { admin } = useSelector((store) => store.user);

  useEffect(()=>{
    if(admin){
      navigate("/")
    }
    },[])
    
  let [data, setData] = useState({ email: "", password: "" });
  const onChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({ ...prevData, [id]: value }));
  };

const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const dispatch = useDispatch();

  let [error, setError] = useState();

  const { mutate, isLoading } = useMutation(apis.adminLogin, {
    onError: function ({ message }) {
      setError("");
      console.log(message);
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: admin, status, headers }) => {
      if (status === 200) {
        const token = headers["x-auth-token"];
        console.log(admin,"ADMINDATA")
        dispatch(setUser(data));
        toast.success("Admin login sucessfully", { id: 1});
        navigate("/");
      }
    },
  });

  const signIn = (e) => {
    e.preventDefault();

    // let data = {email :  data?.email , password :  data?.password}

    let response = errorValidate(loginValidate(data));
    if (response === true) {
      setError("")
      mutate(data);
    } else {
      setError(response);
    }
  };


  return (
    <div className="account-section bg_img">
      <div className="container-fluid">
        <div className="account-title">
          <a href="#" className="logo mb-4">
            <img
              src={imageUrl("logo.png")}
              style={{ borderRadius: "25px", width: "100px" }}
              alt="logo"
            />
          </a>
        </div>

        <div className="account-wrapper">
          <div className="account-body">
            <div className="account-form" id="contactForm">
              <div className="head-section m-4">
              <p>Admin Login</p>
              </div>
              <div className="form-group">
                <label for="email">Email </label>
                <input
                  type="email"
                  onChange={onChange}
                  id="email"
                  placeholder="Enter Your Email"
                  name="email"
                />
                {error?.email ? (
                  <span className="error-message">{error?.email}</span>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group">
                <label for="password">Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter Your Password"
                  id="password"
                  name="password"
                  onChange={onChange}
                />
                <FontAwesomeIcon
                  onClick={togglePassword}
                  icon={passwordShown ? faEyeSlash : faEye}
                  className="form-icon input-icon position-absolute mt-3 ml-n4"
                />
              </div>
              {error?.password ? (
                <span className="error-message">{error?.password}</span>
              ) : (
                <></>
              )}

              {/* <div className="form-group text-center" id="formProgress"></div> */}

               {/* <div className="form-group required d-flex justify-content-end align-items-end" id="formProgress" onClick={()=>navigate("/forget")}>Forget Password</div> */}

              <div className="form-grou text-center mt-1">
                <button
                className="add-submit-btn "
                  disabled={isLoading}
                  onClick={signIn}>
                  {isLoading ? "Loading" : "Log In"}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
