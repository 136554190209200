import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import DeleteModal from "../components/DeleteModal";
import imageUrl from "../utils/ImageUrl";
import { useNavigate } from "react-router-dom";
import ThreeDots from "../components/shared/svgs/ThreeDots";
import backendImage from "../utils/backendImage";
import AddSongsToChartModal from "../components/AddSongsToChartModal";

const Chart = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [addShow, setAddShow] = useState(false);


  const [selectedDetails, setSelectedDetails] = useState({});
  
  const [activeIndex, setActiveIndex] = useState("");
  const [id, setId] = useState("");

  const handleClose = () => {
    setShow(false);
    setId("");
  };
  const handleAddClose = () => {
    setAddShow(false);
    setId("");
    setSelectedDetails({})
  };
  const [values, setValues] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["getAllCharts"],
    () => apis.getAllCharts(),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {
        let chartData = data?.data?.data;
        setValues(chartData);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    apis.deleteChart,
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: ({ data }) => {
        if (data.success) {
          refetch();
          console.log(data);
          toast.success(data.message, { id: 1 });
          handleClose();
        }
        else {
          toast.error(data.message, { id: 1 });
          handleClose();
        }
      },
    }
  );



  const { mutate: mutateAddedSong, isLoading: isLoadingAdded } = useMutation(
    apis.addSongToChart,
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: ({ data }) => {
          console.log(data);
          toast.success("Song Addedd Sucessfully", { id: 1 });
          handleAddClose();
          refetch();
      },
    }
  );


  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };
  const handleAddSongShow = (id) => {
    setAddShow(true);
    setId(id);
  };

  // Function to toggle the active index
  const toggleActiveIndex = (chartId) => {
    setActiveIndex(activeIndex === chartId ? "" : chartId);
  };
  const handleDelete = async (id) => {
    mutateDelete(id);
    setId("");
  };


  const handleSubmitAddSongs = (data)=>{
    mutateAddedSong({songIds:data , chartId:id})
  }
  if (isLoading || isLoadingDelete)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" className="yellow" />
      </div>
    );

  const renderChartList =
    values?.length > 0 &&
    values.map((Chart, index) => {
      const {
        name,
        title,
        viewCount,
        description,
        chartId,
        rank,
        region,
        songs,
        image,
      } = Chart;
      return (
        <tbody key={index}>
          <tr>
            <td>{index + 1}</td>
            <td>
              <img
                      style={{ width: "50px" ,
                      height: "35px" }}
                      src={backendImage(image)} alt="" />
            </td>

            <td>{name}</td>
            <td>{title}</td>
            <td>{description}</td>
            {/* <td>{region}</td> */}
            <td>{rank}</td>

            <td>{songs?.length || 0}</td>
            <td>{viewCount}</td>
            <td>
              {/* <Button variant="outline-danger" className='mx-2 my-2 button-size action-btn' onClick={() => handleShow(id)}>Delete</Button> */}
              <div
                style={{ position: "relative" }}
                onClick={() => toggleActiveIndex(chartId)}
              >
                <ThreeDots />
                {chartId === activeIndex && (
                  <div className="option-lists">
                    <ul>
                      <li onClick={() => navigate(`/edit-chart/${chartId}`)}>
                        Edit Chart
                      </li>
                      <li
                        onClick={() => {
                          handleAddSongShow(chartId)
                          setSelectedDetails(Chart)
                        }}
                        size="small"
                      >
                        Add Songs
                      </li>
                      <li onClick={() => handleShow(chartId)} size="small">
                        Delete Chart
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      );
    });

    
  return (
    <>
      <Container fluid className="main-height">
        <Row>
          <Col lg={12} md={12} className="p-0">
            <div className="custom-chart-margin">
              <h5 className="section-title"></h5>

              <div className="d-flex justify-content-between">
                <h5 className="section-title">Charts</h5>
                <button
                  className="add-submit-btn"
                  onClick={() => navigate("/add-chart")}
                >
                  Add Charts
                </button>
              </div>

              <div className="overflow-scroll user-table">
                <table className="table table-striped mt-3">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Chart Image</th>
                      <th scope="col">Chart Name</th>
                      <th scope="col">Chart Title</th>
                      <th scope="col">Chart Description</th>
                      {/* <th scope="col">Region</th> */}
                      <th scope="col">Rank</th>

                      <th scope="col">No Of Song</th>
                      <th scope="col">View Count</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {renderChartList}
                </table>
              </div>
            </div>
          </Col>
        </Row>

        <DeleteModal
          show={show}
          handleClose={handleClose}
          handleSubmit={handleDelete}
          loader={isLoadingDelete}
          id={id}
          title={"Delete Chart"}
        />

        <AddSongsToChartModal
          handleClose={handleAddClose}
          handleSubmitAddSongs={handleSubmitAddSongs}
          loader={isLoadingAdded}
          id={id}
          title={"Add Song In Chart"}
          show={addShow}
          selectedDetails={selectedDetails}
        />
      </Container>
    </>
  );
};
export default memo(Chart);
