import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apis from "../services";
import { loginValidate } from "../validation/loginValidation";
import { errorValidate } from "../validation/errorHandleJoi";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setUser } from "../redux/slices/userSlice";
import imageUrl from "../utils/ImageUrl";
import { useMutation } from "@tanstack/react-query";
import { forgetValidate } from "../validation/forgetValidation";

import OtpInput from 'react18-input-otp';
const Verification = () => {

  const { state } = useLocation();
  const navigate = useNavigate();

 const { admin } = useSelector((store) => store.user);

 useEffect(() => {
  if(!state?.email)
  {
    navigate("/sign-in");
  }
  
  if (admin) {
    navigate("/");
  }
}, []);


  let [data, setData] = useState({ email: state?.email, otp: "" });
  const onChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({ ...prevData, [id]: value }));
  };

const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const dispatch = useDispatch();

  let [error, setError] = useState();

  const { mutate, isLoading } = useMutation(apis.OTPVerification, {
    onError: function ({ message }) {
      setError("");
      console.log(message);
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: admin, status, headers }) => {
      if (status === 200) {
            navigate("/");
      }
    },
  });

  const submitHandler = (e) => {
    e.preventDefault();

    // let data = {email :  data?.email , password :  data?.password}
console.log("CALL")
    // let response = errorValidate(forgetValidate(data));
    // if (response === true) {
    //   setError("")
    //   mutate(data);
    // } else {
    //   setError(response);
    // }
    mutate(data);
  };


  return (
    <div className="account-section bg_img">
      <div className="container-fluid ml-3">
        <div className="account-title">
          <a href="/" className="logo mb-4">
            <img
              src={imageUrl("logo.png")}
              style={{ borderRadius: "25px", width: "100px" }}
              alt="logo"
            />
          </a>
        </div>

        <div className="account-wrapper">
          <div className="account-body">
            <form onSubmit={submitHandler}>
            <div className="account-form" id="contactForm">
              <div className="head-section m-4">
              <p>OTP Verification</p>
              </div>
              {/* <div className="form-group">
                <label for="email">Email </label>
                <input
                  type="email"
                  required
                  disabled
                  onChange={onChange}
                  id="email"
                  placeholder="Enter Your Email"
                  name="email"
                />
              </div> */}
              
             <label for="email">Email Verification Code</label>
              
              <div className="otp-wrapper d-flex align-items-center justify-content-center">
                    <OtpInput
                      value={data?.otp}
                      onChange={(value)=> setData((prevData)=>({...prevData,otp:value}))}
                      numInputs={4}
                      isInputNum={true}
                      inputStyle={{
                        width: "63.32px",
                        height: "56px",
                        margin: "1rem 0.7rem",
                        fontSize: "2rem",
                        borderRadius: 4,
                        border: "none",
                        backgroundColor: "#EDEDED",
                      }}
                    />
                  </div>
              <div className="form-group text-center" id="formProgress"></div>

            
              <div className="form-group text-center">
                <button
                 type="submit"
                 disabled={isLoading}
                  >
                  {isLoading ? "Loading" : "Send"}
                </button>
              </div>
              {/* <div className="form-group required d-flex justify-content-center" id="formProgress" onClick={()=>navigate("/sign-in")}>Login Now</div> */}
            </div>
            </form>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
