import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import apis from "../../services";
import Select from "react-select";

const AddSongForm = ({
  formData,
  submitHandler,
  onChangeHandler,
  heading,
  songId,
  loading,
}) => {
  const reqireState = songId ? false : true;

  const locations = ["UK","US","UAE","CA","IND"];

  const [ageGroup, setAgeGroup] = useState([]);

  const [keyword, setKeyword] = useState([]);

  const [genre, setGenre] = useState([]);
  const [chips, setChips] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    console.log(file);
    console.log(selectedImage?.name);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };



  const getDataList = async () => {
    try {
      const response = await apis.getAgegroupKeywordAndGenre();
      console.log(response?.data, "ASDDDDDDDDDDDD");

      response?.data?.data?.ageGroups?.map((item) => {
        setAgeGroup((prevState) => [...prevState, item?.name]);
      });

      response?.data?.data?.genres?.map((item) => {
        setGenre((prevState) => [...prevState, item?.type]);
      });
      response?.data?.data?.keywords?.map((item) => {
        setKeyword((prevState) => [...prevState, item?.name]);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDataList();
  }, []);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedImage]);

  const submit = (e) => {
    e.preventDefault();
    submitHandler(formData);
    // e.currentTarget.reset();
  };


  console.log(formData,"FORMADATA")
  return (
    <Fragment>
      <div className="multiform">
        <Form onSubmit={submit} className="my-4">
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Song Name</Form.Label>
                <Form.Control
                  type="text"
                  name="songName"
                  placeholder="Song Name"
                  required
                  onChange={onChangeHandler}
                  value={formData?.name}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Artist Name</Form.Label>
                <Form.Control
                  type="text"
                  name="artistName"
                  placeholder="Artist Name"
                  required
                  onChange={onChangeHandler}
                  value={formData?.artistName}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="state">
                <Form.Label>Location</Form.Label>
                <Form.Select
                  name="location"
                  required
                  onChange={onChangeHandler}
                  value={formData?.location}
                >
                  <option value="" disabled>Select Location</option>
                  {locations.map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {/* <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Song Lyrics</Form.Label>
                <Form.Control
                  type="text"
                  name="lyrics"
                  placeholder="Song Lyrics"
                  required
                  onChange={onChangeHandler}
                  value={formData?.lyrics}
                />
              </Form.Group>
            </Col> */}
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="state">
                <Form.Label>Genre</Form.Label>
                <Form.Select
                  name="genre"
                  required
                  onChange={onChangeHandler}
                  value={formData?.genre}
                >
                  <option  value="" disabled>Select Genre</option>
                  {genre.map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
         
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="state">
                <Form.Label>Keywords Name</Form.Label>
                {/* <Form.Select
                  name="keyword"
                  required
                  onChange={onChangeHandler}
                  value={formData?.keyword}
                >
                  <option disabled>Select Keyword...</option>
                  {keyword.map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </Form.Select> */}
        <Select
        required
      isMulti
      options={keyword.map((value) => ({ value, label: value }))}
      onChange={(selectedKeywords) =>
        onChangeHandler({
          target: {
            name: "keyword",
            value: selectedKeywords.map((keyword) => keyword.value),
          },
        })
      } 
       styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: "#212121",
          borderRadius: "10px",
          borderColor: "transparent",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#808080",
          fontFamily: 'Poppins',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '500'
        }),
      }}
      placeholder="Select Keywords"
    />
                {/* <Select
                  isMulti
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={keyword}
                /> */}
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="state">
                <Form.Label>Age Group</Form.Label>
                <Form.Select
                  name="ageGroup"
                  required
                  onChange={onChangeHandler}
                  value={formData?.ageGroup}
                >
                  <option value="" disabled>Select AgeGroup</option>
                  {ageGroup.map((value , index) => (
                    <option key={index}>{value}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Song Rank</Form.Label>
                <Form.Control
                  type="number"
                  name="rank"
                  placeholder="Song Rank"
                  required
                  min={1}
                  // max={10}
                  onChange={onChangeHandler}
                  value={formData?.rank}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Song Image</Form.Label>
                <div className="add-recipe-upload">
                  <div className="NFT-image-holder">
                    <input
                      name="image"
                      required={reqireState}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(event) => {
                        onChangeHandler(event);
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Song File</Form.Label>
                <div className="add-recipe-upload">
                  <div className="NFT-image-holder">
                    <input
                      name="file"
                      required={reqireState}
                      type="file"
                      accept="audio/mp3, audio/mp4 ,audio/mpeg3"
                      onChange={(event) => {
                        onChangeHandler(event);
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Lyrics XML File</Form.Label>
                <div className="add-recipe-upload">
                  <div className="NFT-image-holder">
                    <input
                      name="lyrics_xml"
                      required={reqireState}
                      type="file"
                      accept=".xml"
                      onChange={(event) => {
                        onChangeHandler(event);
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Lyrics TXT File</Form.Label>
                <div className="add-recipe-upload">
                  <div className="NFT-image-holder">
                    <input
                      name="lyrics_txt"
                      required={reqireState}
                      type="file"
                      accept=".txt"
                      onChange={(event) => {
                        onChangeHandler(event);
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group >
                <Form.Label>Description</Form.Label>
                <Form.Control
                as="textarea"
                  type="text"
                  name="description"
                  placeholder="Song Description"
                  required
                  onChange={onChangeHandler}
                  value={formData?.description}
                />
              </Form.Group>
            </Col>

            {/* <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Song Image</Form.Label>
                <div className="add-recipe-upload">
                  <div className="NFT-image-holder">
                    <input ref={fileInputRef} type="file" accept="image/png, image/gif, image/jpeg" style={{ display: "none" }} onChange={handleImageUpload}/>
                  </div>
                  <div className="NFT-image-upload-txt">
                    <button onClick={handleButtonClick} className="">Choose File</button>
                    {!selectedImage 
                    ?
                  <p>No file chosen</p>
                :
                <p>{selectedImage?.name}</p>}
                  </div>
                </div>
              
              </Form.Group>
            </Col> */}

            <Col xs={12} md={12} lg={12}>
              <div className="update-submit-btn-parent">
                <Button
                style={{ marginTop: "40px" }}
                  className="update-submit-btn btn btn-warning"
                  type="submit"
                >
                  {" "}
                  {loading ? "Loading" : heading + " Song"}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddSongForm;
