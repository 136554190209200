import { Box } from "@mui/material";
import toast from "react-hot-toast";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AddSongForm from "../components/forms/AddSongForm";
import apis from "../services";

const INITIAL_FORM_STATE = {
  songName: "",
  description: "",
  rank: null,
  artistName: "",
  lyrics_txt: "",
  lyrics_xml:'',
  genre: "",
  image: "",
  file: "",
  keyword: "",
  ageGroup: "",
  location:""
};

function AddUpdateSong() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);
  const [songData, setSongData] = useState(INITIAL_FORM_STATE);
  const { id: songId } = useParams();
  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    const { name, value, files } = e.target;
    if (files)
      return setSongData((prevState) => ({ ...prevState, [name]: files[0] }));
    setSongData((prevState) => ({ ...prevState, [name]: value }));
  };

  const fetchSongDetails = async (songId) => {
    try {
      setLoading(true);
      const res = await apis.getAllSongs({ sortBy: "name", order: "asc" });
      console.log(res);
      if (res?.data) {
        const data = res?.data?.data?.filter((item) => item.songId === songId);
        setSongData(data?.[0]);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);

      console.error("ERROR in fetching Song details", e);
    }
  };

  const onCreateSongHandler = async (data) => {
    try {
      setLoading(true);
      console.log(data, "DATASSSSSSSSSSSSSSSSS");
      const form_data = new FormData();
      for (const [key, value] of Object.entries(data)) {
        // if (typeof value === 'object' && key !== "image") {
        //   for (const [nestedKey, nestedValue] of Object.entries(value)) {
        //     form_data.append(`${key}[${nestedKey}]`, nestedValue);
        //   }
        // }
        // else {
          // if(key === "keyword")
          // {
          //   form_data.append(key, value.split(","));
          // }else{
          //   form_data.append(key, value);
          // }
            form_data.append(key, value);
        
          // }
      }

      const res = await apis.createSong(form_data);
console.log(res?.data?.success,"RES")
if(res?.data?.success)
{
  toast.success("Song Created Successfully");

  setSongData(INITIAL_FORM_STATE);
  navigate("/song");

}
else{
  toast.error(res?.data?.message);
}
  
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error("ERROR on Create Song", e);
      const errorMessages = e?.message.errors;
            if (errorMessages) {      
              for (const key in errorMessages) {
                         if (errorMessages.hasOwnProperty(key)) {
                             toast.error(`${key} :  ${errorMessages[key]}`);
                         }
                     }
                 }else{
                     toast.error("An unexpected error occurred.");
                 }
      // if (e?.message) {
      //   return toast.error(e?.message);
      // }
      // if (e.response && e.response.data && e.response.data.errors) {
      //   const errorMessages = e.response.data.errors;

      //   for (const key in errorMessages) {
      //     if (errorMessages.hasOwnProperty(key)) {
      //       toast.error(errorMessages[key]);
      //     }
      //   }
      // } else {
      //   // Handle unexpected errors
      //   toast.error("An unexpected error occurred.");
      // }
    }
  };
  const onUpdateSongHandler = async (data) => {
    try {
      setLoading(true);
      const form_data = new FormData();
      for (const [key, value] of Object.entries(data)) {
        // if (typeof value === 'object' && key !== "image") {
        //   for (const [nestedKey, nestedValue] of Object.entries(value)) {
        //     form_data.append(`${key}[${nestedKey}]`, nestedValue);
        //   }
        // }
        // else {
        form_data.append(key, value);
        // }
      }
      const res = await apis.updateSong(songId, form_data);
      if (res?.data?.status) {
        toast.success(res.data.message);
        navigate("/song");
        setSongData("");
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);

      const errorMessages = e?.message.errors;
      if (errorMessages) {      
        for (const key in errorMessages) {
                   if (errorMessages.hasOwnProperty(key)) {
                       toast.error(`${key} :  ${errorMessages[key]}`);
                   }
               }
           }else{
               toast.error("An unexpected error occurred.");
           }
    }
  };

  useEffect(() => {
    (async () => {
      if (songId) await fetchSongDetails(songId);
    })();
  }, [songId]);

  const onSubmitHandler = async (data) => {
    if (songId) await onUpdateSongHandler(songData);
    else await onCreateSongHandler(songData);
  };

  return (
    <>
      <Box className="dashboard-main">
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              // height: "100vh",
              // overflow: "auto",
            }}
            className="custom-scroll"
          >
            <div className="container-fluid p-0">
              <h3 className="section-title dark-text text-start mb-0">
                {songId ? "Update" : "Add"} Song
              </h3>
              <AddSongForm
                loading={loading}
                formData={songData}
                onChangeHandler={onChangeHandler}
                submitHandler={onSubmitHandler}
                heading={songId ? "Update" : "Add"}
                songId={songId}
              />
            </div>
          </Box>
        </div>
      </Box>
    </>
  );
}

export default AddUpdateSong;
