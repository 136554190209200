import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, memo } from "react";

import Logo from "../assets/images/logo-icon.png";
import Dashboard from "./shared/svgs/Dashboard";
import Coffee from "./shared/svgs/Coffee";
import Users from "./shared/svgs/Users";
function SideBar({ props, hamburgerActive, SetHamburgurActive }) {
  const location = useLocation();
  const [url, setUrl] = useState(location.pathname);
  useEffect(() => {
    setUrl(location.pathname);
  });
  const [isProjectSubMenuOpen, setIsProjectSubMenuOpen] = useState(false); // State to manage submenu visibility

  function closeNav() {
    var x = window.matchMedia("(max-width: 768px)");
    if (x.matches) {
      document.getElementById("sidebar").style.width = "0";
    }
    // document.getElementById("sidebar").style.position = "sticky";
  }

  const [openSubMenuItems, setOpenSubMenuItems] = useState({});

  const toggleSubMenu = (index) => {
    setOpenSubMenuItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  console.log(openSubMenuItems, "openSubMenuItems");
  const menuItems = [
    {
      // icon: <Dashboard />,
      image: "images/Dashboard.png",
      name: "Dashboard",
      link: "/",
    },
    {
      // icon: (
      //   <Users
      //     style={{ justifyContent: hamburgerActive ? "start" : "center" }}
      //   />
      // ),
      image: "images/Users.png",
      
      name: "Users",
      link: "/users",
    },
    {
      image: "images/Banner.png",
      name: "Banners",
      link: "/banner",
      // subItems: [
      //   { name: 'Add Banner', link: '/add-banner' , icon:  <Coffee />,},
      //   { name: 'View banner', link: '/banner', icon:  <Coffee />, },
      // ],
    },

    {
      image: "images/Song.png",
      name: "Songs",
      link: "/song",
      // subItems: [
      //   { name: 'Add Song', link: '/add-song', icon:  <Coffee />, },
      //   { name: 'List Song', link: '/song' , icon:  <Coffee />,},
      // ],
    },
    {
      image: "images/Chart.png",
      name: "Charts",
      link: "/chart",
      // subItems: [
      //   { name: 'Add Chart', link: '/add-chart' , icon:  <Coffee />,},
      //   { name: 'List Chart', link: '/chart' , icon:  <Coffee />,},
      // ],
    },
    {
      image: "images/Genre.png",
      name: "Genres",
      link: "/genre",
      // subItems: [
      //   { name: 'Add Chart', link: '/add-chart' , icon:  <Coffee />,},
      //   { name: 'List Chart', link: '/chart' , icon:  <Coffee />,},
      // ],
    },
    {
      name: "Age Groups",
      image: "images/AgeGroup.png",
      link: "/age-group",
      // subItems: [
      //   { name: 'Add Chart', link: '/add-chart' , icon:  <Coffee />,},
      //   { name: 'List Chart', link: '/chart' , icon:  <Coffee />,},
      // ],
    },
    {
      image: "images/Keyword.png",
      name: "Keywords",
      link: "/keywords",
      // subItems: [
      //   { name: 'Add Chart', link: '/add-chart' , icon:  <Coffee />,},
      //   { name: 'List Chart', link: '/chart' , icon:  <Coffee />,},
      // ],
    },
    {
      image: "images/SingHistory.png",
      name: "Sing History",
      link: "/sing-history-user",
      // subItems: [
      //   { name: 'Search Song', link: '/sing-history-user' , icon:  <Coffee />,},
      //   { name: 'Search User', link: '/sing-history-song' , icon:  <Coffee />,},
      // ],
    },
  ];

  return (
    <>
      <div
        className={hamburgerActive ? 'sidebar show-sidebar' : 'sidebar'}
              id="sidebar">
        <div className="close-nav-section">
          <div className="logo">
            <div className="d-flex justify-content-center m-2">
              <img
                className="p-2"
                style={{ width: 55, height: 55 }}
                src="images/logo.png"
              />

              {hamburgerActive && <h4 className="primary-text">YELODY</h4>}
            </div>
          </div>
          {/* <i className="fa-solid fa-times close-sidebar-icon" ></i> */}
        </div>

        {/* <div className="sidebar-scroll">
          <ul className="sidebar-ul">
            {menuItems.map((menuItem, index) => (
              <li
                key={index}
                className={url === menuItem.link ? 'active' : ''}
                style={{ paddingInline: hamburgerActive ? '20px' : '35px' }}
              >
                <Link to={menuItem.link} style={{ justifyContent: hamburgerActive ? 'start' : 'center' }}>
                  {menuItem.icon}
                  {hamburgerActive && <div><span>{menuItem.name}</span></div>}
                </Link>
              </li>
            ))}
          </ul>
        </div> */}

        <div className="sidebar-scroll">
          <ul className="sidebar-ul">
            {menuItems.map((menuItem, index) => (
              <li
                key={index}
                className={
                  url === menuItem.link || index === openSubMenuItems[index]
                    ? "active"
                    : ""
                }
                style={{ paddingInline: hamburgerActive ? "20px" : "35px" }}
              >
                {menuItem.subItems ? (
                  // Render the submenu items if subItems exist
                  <>
                    {/* <Link
                      className={openSubMenuItems[index] ? 'active' : ''}
                      onClick={() => {
                        toggleSubMenu(index);
                        SetHamburgurActive(true);
                      }}
                      style={{ justifyContent: hamburgerActive ? 'start' : 'center' }}
                    >
                     
                      {menuItem.icon}
                      {hamburgerActive && <div><span>{menuItem.name}</span></div>}
                    </Link> */}

                    {openSubMenuItems[index] && (
                      <ul >
                        {menuItem.subItems.map((subItem, subIndex) => (
                          <li
                            className={url === subItem.link ? "active" : ""}
                            style={{
                              justifyContent: hamburgerActive
                                ? "start"
                                : "center",
                            }}
                            key={subIndex}
                          >
                            <Link  to={subItem.link}>
                              {subItem.icon}
                              {hamburgerActive && (
                                <div>
                                  <span>{subItem.name}</span>
                                </div>
                              )}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  // Render regular menu items
                  <Link
                    onClick={()=>SetHamburgurActive(false)}
                    to={menuItem.link}
                    style={{
                      justifyContent: hamburgerActive ? "start" : "center"
                    }}
                  >
                    {menuItem.icon ? (
                      menuItem.icon
                    ) : (
                      <img className="p-2 sidebar-image" src={menuItem.image} />
                    )}

                    {hamburgerActive && (
                      <div>
                        <span>{menuItem.name}</span>
                      </div>
                    )}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
export default memo(SideBar);
