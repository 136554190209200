import { Form, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import DeleteModal from "../components/DeleteModal";
import backendImage from "../utils/backendImage";

const Users = () => {
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State to hold the search input value

  const [id, setId] = useState("");

  const handleClose = () => {
    setShow(false);
    setId("");
  };
  const [values, setValues] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["getAllUsers"],
    () => apis.getAllUsers(),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {
        let userData = data?.data?.data;
        setValues(userData);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    apis.deleteUser,
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: ({ data }) => {
        if (data) {
          refetch();
          toast.success("User Deleted Successfully", { id: 1 });
          handleClose();
        }
      },
    }
  );

  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const handleDelete = async (id) => {
    mutateDelete(id);
    setId("");
  };

  if (isLoading || isLoadingDelete)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" className="yellow" />
      </div>
    );


    const renderUsersList = values?.length > 0 && values?.filter((item) => {
      const searchValue = searchTerm.toLowerCase();
      return (
        item?.userName?.toLowerCase().includes(searchValue) ||
        item?.phone?.toLowerCase().includes(searchValue)
      )
    })?.map((user, index) => {
        const {
          userName,
          email,
          yeloPoints,
          sungs,
          id,
          registrationDate,
          lastVisitDate,
          description,
          image
        } = user;
        return (
          <tbody key={index}>
            <tr>
              <td>{index + 1}</td>
              <td>
              <img
              style={{ width: "50px" ,
               height: "35px" }}
                src={backendImage(image)}
                alt=""
              />
            </td>
              <td>{userName}</td>
              <td>{email}</td>
              <td>{description}</td>
              <td>{sungs || 0}</td>
              <td>{lastVisitDate}</td>
              <td>{registrationDate}</td>
              <td>{yeloPoints || 0}</td>
              {/* <td>
            <Button variant="outline-danger" className='mx-2 my-2 button-size action-btn' onClick={() => handleShow(id)}>Delete</Button>
          </td> */}
            </tr>
          </tbody>
        );
      });

  return (
    <>
      <Container fluid className="main-height">
        <Row>
          <Col lg={12} md={12} className="p-0">
            <div className="custom-chart-margin">
              <div className="d-flex justify-content-between">
                <h5 className="section-title">Users</h5>
                <div className="m-3">
                  {" "}
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="overflow-scroll user-table">
                <table className="table table-striped mt-3">
                  <thead>
                    <tr>
                      
                    <th scope="col">#</th>
                      <th scope="col">User Image</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Description</th>
                      <th scope="col">Sung Songs</th>
                      <th scope="col">Last Visit Date</th>
                      <th scope="col">Registration Date</th>
                      <th scope="col">Yelo Score Points</th>

                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  {renderUsersList}
                </table>
              </div>
            </div>
          </Col>
        </Row>

        <DeleteModal
          show={show}
          handleClose={handleClose}
          handleSubmit={handleDelete}
          loader={isLoadingDelete}
          id={id}
          title={"Delete User"}
        />
      </Container>
    </>
  );
};
export default memo(Users);
