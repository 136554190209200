import { Box } from "@mui/material";
import toast from "react-hot-toast";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AddChartForm from "../components/forms/AddChartForm";
import apis from "../services";

const INITIAL_FORM_STATE = {
  image: "",
  name: "",
  title: "",
  newFlag: "TRUE",
  description: "",
  rank: null,
  region: "",
};

function AddUpdateChart() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(INITIAL_FORM_STATE);
  const { id: chartId } = useParams();
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState(null);
  const onChangeHandler = (e) => {
    const { name, value, files } = e.target;
    if (files)
    {
      setImagePreview(URL.createObjectURL(files[0]));
      return setChartData((prevState) => ({ ...prevState, image: files[0] }));
   
    }
    else{
      setChartData((prevState) => ({ ...prevState, [name]: value }));
    }  
  };

  const fetchChartDetails = async (chartId) => {
    try {
      setLoading(true);
      const res = await apis.getOneChart(chartId);
      console.log(res, "SDADASRESD");
      if (res?.data?.data) {
        setChartData(res?.data?.data);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      // if (e.response && e.response.data && e.response.data.errors) {
      //     const errorMessages = e.response.data.errors;

      //     for (const key in errorMessages) {
      //         if (errorMessages.hasOwnProperty(key)) {
      //             toast.error(errorMessages[key]);
      //         }
      //     }
      // } else {
      //     // Handle unexpected errors
      //     toast.error("An unexpected error occurred.");
      // }
    }
  };

  const onCreateChartHandler = async (data) => {
    try {
      if(data?.image == "")
      {
       return toast.error("Please select image");
      }
      setLoading(true);
      const form_data = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === "object" && key !== "image") {
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            form_data.append(`${key}[${nestedKey}]`, nestedValue);
          }
        } else {
          form_data.append(key, value);
        }
      }

      const res = await apis.createChart(form_data);

      if (res?.data?.success) {
        
      setChartData(INITIAL_FORM_STATE);
      navigate("/chart");
        toast.success("Chart Created Successfully");
      } else {
        toast.error(res?.data?.message);
      }


      setLoading(false);
    } catch (e) {
      setLoading(false);
      const errorMessages = e?.message.errors;
      if (errorMessages) {
        for (const key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(`${key} :  ${errorMessages[key]}`);
          }
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
      // if (e.response && e.response.data && e.response.data.errors) {
      //     const errorMessages = e.response.data.errors;

      //     for (const key in errorMessages) {
      //         if (errorMessages.hasOwnProperty(key)) {
      //             toast.error(errorMessages[key]);
      //         }
      //     }
      // } else {
      //     // Handle unexpected errors
      //     toast.error("An unexpected error occurred.");
      // }
    }
  };
  const onUpdateChartHandler = async (data) => {
    try {
      setLoading(true);
      const form_data = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === "object" && key !== "image") {
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            form_data.append(`${key}[${nestedKey}]`, nestedValue);
          }
        } else {
          if (key === "image") {
            if (value?.name) {
              form_data.append(key, value);
            }
          } else {
            form_data.append(key, value);
          }
        }
      }
      const res = await apis.updateChart(chartId, form_data);
      
      if(res?.data?.success)
      {
        toast.success("Chart Updated Successfully");
        navigate("/chart");
        setChartData("");
      }      
      else {
        toast.error(res?.data?.message);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);

      const errorMessages = e?.message.errors;
      if (errorMessages) {
        for (const key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(`${key} :  ${errorMessages[key]}`);
          }
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
      // if (e?.message) {
      //     return toast.error(e?.message);
      //   }
      // if (e?.response && e?.response?.data && e?.response?.data?.errors) {
      //     const errorMessages = e.response.data.errors;

      //     for (const key in errorMessages) {
      //         if (errorMessages.hasOwnProperty(key)) {
      //             toast.error(errorMessages[key]);
      //         }
      //     }
      // } else {
      //     toast.error("An unexpected error occurred.");
      // }
    }
  };

  useEffect(() => {
    (async () => {
      if (chartId) await fetchChartDetails(chartId);
    })();
  }, [chartId]);

  const onSubmitHandler = async (data) => {
    if (chartId) await onUpdateChartHandler(chartData);
    else await onCreateChartHandler(chartData);
  };

  return (
    <>
      <Box className="dashboard-main">
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              // height: "100vh",
              // overflow: "auto",
            }}
            className="custom-scroll"
          >
            <div className="container-fluid p-0">
              <h3 className="section-title dark-text text-start mb-0">
                {chartId ? "Update" : "Add"} Chart
              </h3>
              <AddChartForm
                loading={loading}
                formData={chartData}
                onChangeHandler={onChangeHandler}
                submitHandler={onSubmitHandler}
                heading={chartId ? "Update" : "Add"}
                chartId={chartId}
                imagePreview={imagePreview}
              />
            </div>
          </Box>
        </div>
      </Box>
    </>
  );
}

export default AddUpdateChart;
