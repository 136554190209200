import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
const AddAgeGroupForm = ({
  formData,
  submitHandler,
  onChangeHandler,
  heading,
  ageGroupId,
  loading
}) => {
  const reqireState = ageGroupId ? false : true;

  const submit = (e) => {
    e.preventDefault();
    submitHandler(formData);
    // e.currentTarget.reset();
  };

  return (
    <Fragment>
      <div className="multiform">
        <Form onSubmit={submit} className="my-4">
          <Row className="d-flex justify-content-center align-items-center">
          
          <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Age Group</Form.Label>
                <Form.Control
                  type="text"
                  name="ageGroup"
                  placeholder="Age Group"
                  required={reqireState}
                  onChange={onChangeHandler}
                  value={formData?.ageGroup}
                />
              </Form.Group>
            </Col>
      
            <Col xs={12} md={12} lg={12}>
              <div className="update-submit-btn-parent">
              <Button className="update-submit-btn btn btn-warning"  type="submit">
                {" "}     
                {loading ? "Loading":  heading + " Age Group"}
              </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddAgeGroupForm;
