import React, { useState, useEffect } from 'react'
import { Button, Col,Table, Container, Form, Modal as CustomModal, Row } from "react-bootstrap";
import Cross from './shared/svgs/Cross';

const DeleteModal = ({ show, handleClose, handleSubmit ,id,loader,title}) => {

    console.log(id,"IDDDDDDDDDDDDDDDDDDDDDDD")
    return (
        <CustomModal show={show} onHide={handleClose} centered className='custom-modal' >
            <CustomModal.Header style={{position : 'relative'}}>
                <CustomModal.Title className='text-color'>{title}</CustomModal.Title>
                <div className='cross'  onClick={()=>handleClose()} style={{cursor : 'pointer'}}>
                    <Cross/>
                </div>
            </CustomModal.Header>
            <CustomModal.Body>

                <div className="dashboard-box reward-token-box ">
                        <Form.Group
                            className=""
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className ='text-color'>Are you sure you want to delete?</Form.Label>
                           
                        </Form.Group>

                </div>
            </CustomModal.Body>
            <CustomModal.Footer >
                <div className='d-flex'style={{gap:'20px'}}>
                <button className='custom-btn round-btn'  onClick={()=>handleClose()}>
                    Close
                </button>

                {/* <button onClick={()=>handleClose()} className='custom-btn round-btn' type='submit'>
                 Delete
                </button> */}
            
            
                <button onClick={()=>handleSubmit(id)} className='custom-btn round-btn' type='submit' disabled={loader}>
                 {loader ? "Loading" : "Delete"}
            
                </button>
            
                </div>
                </CustomModal.Footer>
      
        </CustomModal>

    )
}

export default DeleteModal;