import { Box } from "@mui/material";
import toast from "react-hot-toast";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import apis from "../services";
import AddKeywordsForm from "../components/forms/AddKeywordsForm";

const INITIAL_FORM_STATE = { keyword: "" };

function AddUpdateKeywords() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);
  const [KeywordsData, setKeywordsData] = useState(INITIAL_FORM_STATE);
  const { id: KeywordsId } = useParams();
  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    const { name, value, files } = e.target;
    if (files)
      return setKeywordsData((prevState) => ({
        ...prevState,
        image: files[0],
      }));
    setKeywordsData((prevState) => ({ ...prevState, [name]: value }));
  };

  const fetchKeywordsDetails = async (KeywordsId) => {
    try {
      setLoading(true);
      const res = await apis.getOneKeywords(KeywordsId);
      console.log(res);
      if (res?.data?.data) {
        setKeywordsData({ keyword: res?.data?.data?.name });
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);

      // if (e.response && e.response.data && e.response.data.errors) {
      //     const errorMessages = e.response.data.errors;

      //     for (const key in errorMessages) {
      //         if (errorMessages.hasOwnProperty(key)) {
      //             toast.error(errorMessages[key]);
      //         }
      //     }
      // } else {
      //     toast.error("An unexpected error occurred.");
      // }
    }
  };

  const onCreateKeywordsHandler = async (data) => {
    try {
      setLoading(true);
      const res = await apis.createKeywords(data);
      if(res?.data?.success)
      {
      toast.success("Keywords Created Successfully");
      setKeywordsData(INITIAL_FORM_STATE);
      navigate("/keywords"); 
    }
      else {
        toast.error(res?.data?.message);
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const errorMessages = e?.message.errors;
      if (errorMessages) {
        for (const key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(`${key} :  ${errorMessages[key]}`);
          }
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
      // if (e?.message) {
      //     return toast.error(e?.message);
      //   }
      // if (e.response && e.response.data && e.response.data.errors) {
      //     const errorMessages = e.response.data.errors;

      //     for (const key in errorMessages) {
      //         if (errorMessages.hasOwnProperty(key)) {
      //             toast.error(errorMessages[key]);
      //         }
      //     }
      // } else {
      //     // Handle unexpected errors
      //     toast.error("An unexpected error occurred.");
      // }
    }
  };
  const onUpdateKeywordsHandler = async (data) => {
    try {
      setLoading(true);

      const res = await apis.updateKeywords(KeywordsId, data);
      if (res?.data?.success) {
        toast.success("Keywords Updated Successfully");
        navigate("/keywords");
        setKeywordsData("");
      } else {
        toast.error(res?.data?.message);
        setLoading(false);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      const errorMessages = e?.message.errors;
      if (errorMessages) {
        for (const key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(`${key} :  ${errorMessages[key]}`);
          }
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
      // if (e?.message) {
      //     return toast.error(e?.message);
      //   }
      // if (e.response && e.response.data && e.response.data.errors) {
      //     const errorMessages = e.response.data.errors;

      //     for (const key in errorMessages) {
      //         if (errorMessages.hasOwnProperty(key)) {
      //             toast.error(errorMessages[key]);
      //         }
      //     }
      // } else {
      //     // Handle unexpected errors
      //     toast.error("An unexpected error occurred.");
      // }
    }
  };

  useEffect(() => {
    (async () => {
      if (KeywordsId) await fetchKeywordsDetails(KeywordsId);
    })();
  }, [KeywordsId]);

  const onSubmitHandler = async (data) => {
    if (KeywordsId) await onUpdateKeywordsHandler(KeywordsData);
    else await onCreateKeywordsHandler(KeywordsData);
  };

  return (
    <>
      <Box className="dashboard-main">
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              // height: "100vh",
              // overflow: "auto",
            }}
            className="custom-scroll"
          >
            <div className="container-fluid p-0">
              <h3 className="section-title dark-text text-start mb-0">
                {KeywordsId ? "Update" : "Add"} Keywords
              </h3>
              <AddKeywordsForm
                loading={loading}
                formData={KeywordsData}
                onChangeHandler={onChangeHandler}
                submitHandler={onSubmitHandler}
                heading={KeywordsId ? "Update" : "Add"}
                KeywordsId={KeywordsId}
              />
            </div>
          </Box>
        </div>
      </Box>
    </>
  );
}

export default AddUpdateKeywords;
