import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TbPhotoEdit } from "react-icons/tb";
import dummy from "../../assets/images/dummy.png"
import backendImage from "../../utils/backendImage";

const AddBannerForm = ({
  formData,
  submitHandler,
  onChangeHandler,
  heading,
  bannerId,
  loading,
  imagePreview
}) => {
  const reqireState = bannerId ? false : true;

  const location = ["side", "main", "mobile","outside","inside"];

  const [chips, setChips] = useState([]);
 
   const fileInputRef = useRef();

   const triggerFileInput = () => {
     fileInputRef.current.click();
   };



  const submit = (e) => {
    e.preventDefault();
    submitHandler(formData);
    // e.currentTarget.reset();
  };
  console.log(formData,"formDataformDataformData")



  return (
    <Fragment>
      <div className="multiform">
        <Form onSubmit={submit} className="my-4">
          <Row className="d-flex justify-content-center align-items-center">
          <Col xs={12} md={12} lg={12}>
            <div className="profile_image_wrapper">
          <img
            src={imagePreview || backendImage(formData?.image)}
            onClick={triggerFileInput}
            className="img-fluid d-block mx-auto ProfileImage shadow-sm"
            alt="cate"
            onError={(e) => {
              e.target.src = dummy;
            }}
          />
          <TbPhotoEdit
          size={30}
          onClick={triggerFileInput}
            className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
          />
           <input
            type="file"
            name="image"
            // required={reqireState}
            accept="image/png, image/gif, image/jpeg"
            onChange={(event)=>{
              onChangeHandler(event)
                }}
            ref={fileInputRef}
            className="d-none"
          />
        </div>
              {/* <Form.Group className="mb-3">
                <Form.Label>Banner Image</Form.Label>
                <div className="add-recipe-upload">
                  <div className="NFT-image-holder">
                    <input 
                     required={reqireState}
                    type="file" accept="image/png, image/gif, image/jpeg" onChange={(event)=>{
                      onChangeHandler(event)
                        }}/>
                  </div>
               
                </div>
          
              </Form.Group> */}
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="state">
                <Form.Label>Banner Location</Form.Label>
                <Form.Select
                  name="location"
                  required
                  defaultValue={formData?.location}
                  onChange={onChangeHandler}
                  value={formData?.location}
                >
                  <option disabled>Select Location ...</option>
                  {location.map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
     
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Banner URL</Form.Label>
                <Form.Control
                  type="text"
                  name="url"
                  placeholder="Banner URL"
                  required={reqireState}
                  onChange={onChangeHandler}
                  value={formData?.url}
                />
              </Form.Group>
            </Col>
      
            <Col xs={12} md={12} lg={12}>
              <div className="update-submit-btn-parent">
              <Button disabled={loading} className="update-submit-btn btn btn-warning"  type="submit">
                {" "}
                {loading ? "Loading":  heading + " Banner"}
              </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddBannerForm;
